/* Variables */
:root {
	--brand-font-family: 'Serif', sans-serif;
	--text-font-family: 'Helvetica', sans-serif;

	--page-color: #fff0f0;
	--section-color: #008000;
	--nav-color: #008000;
	--nav-text-color: #ffffff;
	--font-color: #000000;
	--brand-color: #424242;
	--font-color-secondary: #666666;
	--border-color: #efefef;
	--table-stipe-color: #f1f1f1;
	--table-stipe-color-success: #eafdd0;

	--font-size-brand: 1.5rem;
	--font-size-text: 1rem;
	--font-size-title-sm: 1.2rem;
	--font-size-title-md: 1.5rem;
	--font-size-title-lg: 1.75rem;

	--completed-table-header-color: #4a8d47;
	--completed-table-header-font-color: #ffffff;
	--completed-table-cell-color: #f2fde4;
}

/* Default Settings */

* {
	box-sizing: border-box;
}

body {
	width: 100%;
	min-height: 100vh;
	margin: 0;
	padding:0;
	font-family: var(--text-font-family);
	font-size: var(--font-size-text);
	color: var(--font-color);
	background: var(--page-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: var(--font-color);
	font-weight: 500;
}

h1 {
	font-size: var(--font-size-title-lg);
}

h2 {
	font-size: var(--font-size-title-md);
}

h3 {
	font-size: var(--font-size-title-sm);
}

p {
	font-size: var(--font-size-text);
	color: var(--font-color-secondary);
	line-height: 1.2;
}
a{
	text-decoration: none;
	color: var(--nav-text-color);
	font-size: var(--font-size-text);
	font-family: 'serif', sans-serif;
	font-weight: 400;
}


.loading{
	display:flex;
	align-content:center;
	justify-content: center;
	flex-wrap: wrap;
}






/* BEM - Navigation Block */


.dash{
    display: flex;
    width: 85%;
    flex-direction: column;
}



.nav {
	width: 100%;
    display:flex;
    justify-content: space-between;
	background-color: var(--nav-color);
	color: var(--nav-text-color);
}

.twin{
    display: flex;
}

.brand_name{
    margin:13px auto auto 0px;
    font-family:'Montagu Slab', serif; 
    font-size: 20px;
}

.imgi{
    border-radius: 20px;
    margin: 0.7rem 0.7rem 0.5rem 1rem ;
}

.user_name{
    margin:15px 25px auto auto;
}

.cont{
    display:flex;
    align-items: stretch;
}

.navbar{
    display: flex;
    width: 15%;
	height: 200vh;
    flex-direction: column;
    background-color: var(--nav-color);
    color: var(--nav-text-color)
}

.nav-item{
	padding: 10px 5px 15px 20px ;
	border-top: .1px solid #09bd00;
	background-color: #1bb612;
	
}

.active{
	padding: 10px 5px 15px 20px ;
	border: .1 solid #c6ffbf;
	/* border-top: 2px solid #ffdada; */
	background-color: #033a01;

}
.nav-item:hover{
	background-color: #059600;
	box-shadow:  0px 0px 0px 1px #45e563;
}

.nav-item:active{
	background-color:#eafdd0;
}

.form{
	width: 85%;

}


.bold{
	font-weight: 600 !important;
}

.form-part{
    display: flex;
    justify-content: space-around;
    
}

.dashboard-title{
	font-weight: 600;
	background-color: var(--nav-color);
	color: var(--nav-text-color);
	text-align: center;
	margin: 5px;
	padding:8px;
	border-radius: 3px;
}

table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;

	
}

.table-1{
	margin: 10px 5px 5px 5px;
	border-spacing:0px;
}


.tb-head{
	border-bottom: .1px solid rgb(255, 209, 192);
	border-radius: 1px;
	padding:10px;
	background-color: #ffffff;
	text-align:center;
	font-size: var(--font-size-text);
	font-weight: 700;
}


.col{
	border-bottom: .1px solid rgb(255, 209, 192);
	padding:8px;
	text-align:center;
	font-size: var(--font-size-text);
	font-weight: 700;
}
.col:hover{
	box-shadow:  0px 0px 0px 1px #ffc5c5;
}

button{
	color: rgb(255, 235, 235);
	background-color: #858585;
	font-size: medium;
	border: none;
	border-radius: 5px;
	padding: 7px 12px;	
	-moz-box-shadow:  1px 1px 2px #e3d6d6,
	-1px -1px 2px #fee9e9;
	-webkit-box-shadow:  1px 1px 2px #e3d6d6,
	-1px -1px 2px #fee9e9;
	box-shadow:  1px 1px 2px #ffffff,
             -1px -1px 2px #fee9e9 !important;
	
}

button:hover{
	background-color:rgb(0, 103, 26);
	cursor: pointer;
}

button:active{
	background-color:rgb(129, 74, 74)
}

.submit-button{
	margin: 20px 5px 20px 65px;
	background-color:green;
	
}





.btn {
	color:black;
	background-color:rgb(218, 218, 218);
}

.active-button{
	background-color: #004b00;
}

.delete-button{
	background-color:#c21451;
}
.dawnload-button{
	background-color:rgb(131, 131, 131);
}



.form-group{
	display: flex;
	flex-direction: column;
	width:350px;
	margin:auto;
	padding:20px 10px 3px 10px;
	font-size: var(--font-size-title-sm);
	font-weight: 400;
}

select{
	padding:15px;
	border-radius:5px;
	font-size: var(--font-size-text);
}


label{
	padding: 5px 0px !important;
}


.form-control{
	padding:15px;
	margin:1px;
	font-size: var(--font-size-text);
}

.shadow:focus{
	outline: none;
	border-radius:5px;
	box-shadow:  0px 0px 0px 1px #ffc5c5;
             
}

.shadow{
	border: .01px solid rgb(255, 207, 207);
	border-radius:5px;
	box-shadow:  5px 5px 10px #e3d6d6,
             -5px -5px 10px #ffffff;

}

.form-group-file{
	display: flex;
	flex-direction: column;
	width:90%;
	margin:auto;
	font-size: var(--font-size-title-sm);
	padding:20px 10px 3px 10px;
	font-weight: 400;
}



.re-grp{
	display:flex
}

.re-lab{
	background-color:green;
	width:90px;
	margin:10px 0px 10px 10px;
	padding:10px !important;
	color:white;
	text-align:center;
}

.re-inp{
  width:130px;
  height:40px;
  padding: 5px;
  margin:10px 10px 10px 0px;
}

.thumbnails{
	display:flex;
	background-color:rgb(255, 255, 255);
	justify-content:space-between;
}

/* .thumbnail{
	margin:1px;
	border: 5px inset rgb(74, 92, 247);
} */











.logbody{
	display:flex;
	background-color:rgb(214, 214, 214);
	justify-content:center;
	align-items:center;
	height:100vh;
}

.logform{
	background-color:white;
	height:60vh;
    border-radius:10px;
	transition:.2s;
	transition-timing-function: linear;
}
.logform:hover{
	height:58vh;
	padding:0px 10px 0px 10px;
}

.log{
	padding:10px 80px 10px 3px;
	
}

.log-head{
	font-weight:700;
	color:rgb(12, 12, 53);
	padding:20px 10px 0px 35px;
}
.forhed{
	padding-top:0px;
}

.log-button{
	padding:6px;
	margin:10px 20px 20px 25px;
	background-color: rgb(26, 26, 26)
}

.log-error{
	padding:5px;
	color:red;
}

.offerof{
	font-size: 26px;
	padding:10px 10px 10px 0px ;
}

.user_name{
	font-weight:600;
}




/* BEM Table */

.table {
	margin-top: 1rem;
}

.table--success .table__thead {
	background: var(--completed-table-header-color);
	color: var(--completed-table-header-font-color);
}

.table--success .table__tbody {
	background: var(--completed-table-cell-color);
}

.table--success .table__tbody tr:nth-child(even) {
	background: var(--table-stipe-color-success);
}

.table__table {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
	text-align: left;
}

.table__thead {
	color: var(--font-color-secondary);
	border-bottom: 1px solid var(--border-color);
}

.table__tbody tr:nth-child(even) {
	background: var(--table-stipe-color);
}

.table th,
.table td {
	padding: 1.5rem 2rem;
	width: 25%;
	max-width: 25%;
}

.table__item-input {
	color: var(--font-color-secondary);
}

/* BEM Icon Button */
.icon-button {
	border: none;
	outline: none;
	background: transparent;
	cursor: pointer;
	width: 1.75rem;
	height: 1.75rem;
}
.icon-button__icon {
	width: 100%;
	height: auto;
}

/* 
* Utility Rules Defined Here
* All Type of Utility Tools will Describe Here
*/

.__margin {
	margin: 0.5rem;
}

.__margin--md {
	margin: 1rem;
}

.__margin--lg {
	margin: 2rem;
}

.__margin--y {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}

.__margin--ymd {
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.__margin--ylg {
	margin-top: 2rem;
	margin-bottom: 2rem;
}

.__margin--x {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}

.__margin--xmd {
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.__margin--xlg {
	margin-top: 2rem;
	margin-bottom: 2rem;
}

.__shadow--lg {
	box-shadow: 0 1px 10px 5px rgba(0, 0, 0, 0.25);
	-moz-box-shadow: 0 1px 10px 5px rgba(0, 0, 0, 0.25);
	-webkit-box-shadow: 0 1px 10px 5px rgba(0, 0, 0, 0.25);
}

.__shadow--sm {
	box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
}

.horizontal-line {
	width: 100%;
	height: 0;
	border-bottom: 1px solid var(--border-color);
}

.hide {
	display: none;
}

.show {
	display: block;
}

.auto-grow-input {
	outline: none;
	border-bottom: 1px solid #dddddd;
	padding: 0.2rem 0.5rem;
	color: var(--font-color-secondary);
}

.align-right {
	text-align: right;
}

.align-left {
	text-align: left;
}

.text-secondary {
	color: var(--font-color-secondary);
}
